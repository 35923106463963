<template>
    <div id="template" class="relative sm:w-full md:w-full lg:w-full mx-auto self-center">
        <div class="header flex">
            <div class="back flex items-center justify-center mr-auto" @click="back">
                <feather-icon icon="ArrowLeftIcon"></feather-icon>
            </div>
            <div class="title flex items-center uppercase">New data</div>
            <div class="save flex items-center justify-center ml-auto" @click.prevent="save">
                <feather-icon icon="CheckIcon"></feather-icon>
            </div>
        </div>
        <div class="body sm:w-full md:w-full lg:w-full mx-auto self-center">
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input class="w-full" type="text" label="Title" v-model="form.title"
                        :danger="validation.hasError('form.title')" val-icon-danger="clear"
                        :danger-text="validation.firstError('form.title')" />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <label class="vs-input--label">
                        Content
                    </label>
                    <div id="editor-container" style="height: 100%;" />
                </div>
            </div>

            <div class="vx-row mb-6" style="margin-top: 100px;">
                <div class="vx-col w-full">
                    <label class="vs-input--label mb-3">
                        Thumbnail
                    </label>
                    <picture-input :z-index="90" ref="thumbnail" width="300" height="120" margin="16" accept="image/*"
                        size="2" :alertOnError="false" @error="errorThumbnail"
                        removeButtonClass="vs-component vs-button vs-button-primary vs-button-gradient small w-1/4"
                        button-class="vs-component vs-button w-1/4 vs-button-warning vs-button-border small"
                        :removable="false" :custom-strings="{
                                    upload: '<h1>Bummer!</h1>',
                                    drag: 'Upload'
                                }">
                    </picture-input>
                </div>
            </div>
            <div class="vx-row mb-4">
                <div class="vx-col w-full">
                    <vs-button class="w-full" icon-pack="feather" icon="icon-check" @click.prevent="save">
                        Save</vs-button>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-button class="w-full" type="flat" color="dark" @click="back" icon-pack="feather"
                        icon="icon-arrow-left">
                        Back</vs-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Vue from 'vue'
    import SimpleVueValidation from 'simple-vue-validator'
    import PictureInput from 'vue-picture-input'
    import "quill/dist/quill.core.css";
    import "quill/dist/quill.snow.css";
    import "quill/dist/quill.bubble.css";

    import Quill from 'quill';
    import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
    import {
        base64StringToBlob
    } from 'blob-util';
    import axios from 'axios';
    import ImageResize from 'quill-image-resize-vue';
    const Validator = SimpleVueValidation.Validator
    Vue.use(SimpleVueValidation)
    export default {
        data() {
            return {
                quill: null,
                options: [],
                form: {
                    title: '',
                },
            }
        },
        validators: {
            'form.title': {
                cache: true,
                debounce: 500,
                validator: function (value) {
                    return Validator.value(value).required()
                }
            },
        },
        mounted() {
            Quill.register('modules/imageResize', ImageResize)
            Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
            this.quill = new Quill('#editor-container', {
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        [{
                            align: []
                        }],

                        [{
                            list: 'ordered'
                        }, {
                            list: 'bullet'
                        }],
                        [{
                            indent: '-1'
                        }, {
                            indent: '+1'
                        }],

                        [{
                            size: ['small', false, 'large', 'huge']
                        }],
                        [{
                            header: [1, 2, 3, 4, 5, 6, false]
                        }],
                        ['link', 'image', 'video'],
                        [{
                            color: []
                        }, {
                            background: []
                        }],

                        ['clean'],
                    ],
                    clipboard: {
                        matchVisual: false,
                    },
                    imageDropAndPaste: {
                        handler: this.imageHandler.bind(this),
                    },
                    imageResize: {
                        modules: ['Resize', 'DisplaySize', 'Toolbar']
                    }
                },
                placeholder: '...',
                readOnly: false,
                theme: 'snow',
            });
            this.quill.getModule('toolbar').addHandler('image', (clicked) => {
                if (clicked) {
                    const fileInput = document.createElement('input');
                    fileInput.setAttribute('type', 'file');
                    fileInput.setAttribute('accept',
                        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon');
                    fileInput.classList.add('ql-image');
                    fileInput.addEventListener('change', (e) => {
                        const {
                            files
                        } = e.target;
                        let
                            file;
                        if (files.length > 0) {
                            file = files[0];
                            const {
                                type
                            } = file;
                            const reader = new FileReader();
                            reader.onload = (e) => {
                                // handle the inserted image
                                this.imageHandler(e.target.result, type);
                                fileInput.value = '';
                            };
                            reader.readAsDataURL(file);
                        }
                    });
                    fileInput.click();
                }
            });
        },
        components: {
            PictureInput,
        },
        methods: {
            imageHandler(imageDataUrl, type) {
                if (!type) type = 'image/png';

                const blob = base64StringToBlob(imageDataUrl.replace(/^data:image\/\w+;base64,/, ''), type);
                const formData = new FormData();
                formData.append('image', blob);

                this.uploadPicture(formData).then((res) => {
                    const index = (this.quill.getSelection() || {}).index || this.quill.getLength();
                    if (index) this.quill.insertEmbed(index, 'image', res.data.display_url, 'user');
                });
            },
            uploadPicture(data) {
                return new Promise((resolve, reject) => {
                    this.$vs.loading()
                    delete axios.defaults.headers.common["Authorization"];
                    axios({
                            url: 'https://api.imgbb.com/1/upload?key=' + process.env.VUE_APP_KEY_IMGBB,
                            method: 'POST',
                            data: data,
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            errorThumbnail(error) {
                this.$vs.notify({
                    title: 'Oops!',
                    text: error.message,
                    color: 'danger',
                    position: 'top-center'
                })
            },
            back() {
                this.$router.go(-1)
            },
            save() {
                this.$validate().then(result => {
                    if (result) {
                        this.actionSave().then(async () => {
                                this.$vs.notify({
                                    title: 'Success!',
                                    text: 'Saved!',
                                    color: 'success',
                                    position: 'top-center'
                                })
                                await this.clearForm();
                                this.validation.reset();
                            })
                            .catch(err => {
                                this.$vs.notify({
                                    title: 'Oops!',
                                    text: err.response ? err.response.data.message :
                                        'Something wrong, ' + err,
                                    color: 'danger',
                                    position: 'top-center'
                                })
                                this.$vs.loading.close()
                            })
                    }
                })
            },
            truncString(str, max, add) {
                add = add || '';
                return (typeof str === 'string' && str.length > max ? str.substring(0, max) + add : str);
            },
            getLocalStorage() {
                try {
                    return this.$crypto.Decrypt(
                        // eslint-disable-next-line no-undef
                        localStorage.getItem(process.env.VUE_APP_STORAGE_NAME)
                    );
                } catch (error) {
                    return "";
                }
            },
            actionSave() {
                return new Promise((resolve, reject) => {
                    const storage = this.getLocalStorage();
                    let token;
                    if (storage) {
                        token = JSON.parse(storage);
                    }
                    if (token) {
                        this.$http.defaults.headers.common["Authorization"] =
                            "Bearer " + token.token;
                    }
                    const route = this.truncString(this.form.title.replace(/\s+/g, '-').toLowerCase(), 30, '')
                    const fd = new FormData;
                    fd.append('title', this.form.title)
                    fd.append('description', document.querySelector('.ql-editor').innerHTML || '')
                    fd.append('image', this.$refs.thumbnail.file)
                    fd.append('route', route);
                    this.$vs.loading()
                    this.$http({
                            url: 'v1/en/blog',
                            method: 'POST',
                            data: fd
                        })
                        .then(response => {
                            this.$vs.loading.close()
                            const data = response.data.serve
                            resolve(data)
                        })
                        .catch(error => {
                            this.$vs.loading.close()
                            reject(error)
                        })
                })
            },
            clearForm() {
                this.form.title = ""
                document.querySelector('.ql-editor').innerHTML = ''
                this.$refs.thumbnail.removeImage()
            }
        }
    }
</script>
<style lang="scss" scoped>
    @import "@/assets/scss/vuesax/_variables.scss";

    #template {
        min-height: 400px;
        background-color: #ffffff;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, .05);
        border-radius: .5rem;
        padding-bottom: 30px;
        padding-left: 20px;
        padding-right: 20px;

        .header {
            padding-top: 20px;
            margin-bottom: 40px;

            .help-toggle {
                background: rgba(var(--vs-primary), 1) !important !important;

                span {
                    font-size: 16px;
                    color: rgba(var(--vs-primary), 1) !important !important;
                }
            }

            .back {
                width: 40px;
                height: 40px;
                background: rgba(0, 0, 0, .05);
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .title {
                color: #444444;
                font-weight: 600;
                font-size: 1.3rem;
                line-height: 1;
            }

            .save {
                width: 40px;
                height: 40px;
                background: rgba(var(--vs-primary), 1) !important;
                border-radius: 20px;
                cursor: pointer;

                .feather-icon {
                    color: $white;
                    width: 20px;
                    height: 20px;
                }
            }
        }

        .body {
            .now {
                font-size: 25px;
                text-align: center;
                margin-top: 20%;
            }

            .error-msg {
                color: $danger;
                font-size: 11px
            }

            .icons {
                width: 100%;

                li {
                    width: 40px;
                    height: 40px;
                    border-radius: 3px;
                    border: 1px solid transparent;
                    transition: all .5s;
                    cursor: pointer;

                    .feather-icon {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        background: rgb(255, 255, 255);
                        border: 1px solid rgb(248, 249, 250);
                        box-shadow: 0 0px 10px 0 rgba(0, 0, 0, .05);
                    }

                    &.active {
                        background: rgba(var(--vs-primary), 1) !important;

                        .feather-icon {
                            color: $white;
                        }
                    }
                }
            }
        }
    }
</style>